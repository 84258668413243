*, ::after, ::before {
  box-sizing: border-box;
}
body {
  .fontRegular;
  color: @colors[c10];
  font-size: 16px;
  line-height: 1.3;
}
html,
body {
  scroll-behavior: smooth;
}
body.ar-nooverflow {
  overflow: hidden;
}
#ar2023 {
  input,
  button,
  select,
  textarea {
    .fontRegular;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    .fontBold;
  }
  
  .ar-sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1088;

    & + .ar-main {
      padding-top: 40px;
    }
  }

  each(@colors, {
    // .ar-bg-@{key} {
    //   background-color: @value !important;
    // }
    // .ar-color-@{key} {
    //   color: @value !important;
    //   svg {
    //     fill: @value !important;
    //   }
    // }
    // .ar-borderbottom-@{key} {
    //   border-bottom: round((1 / @rfs), 2) * 1rem solid @value !important;
    // }
  });

  svg {
    height: 100%;
    width: 100%;
  }

  .ar-section-link {
    .fs(22);
    .lh(28);
    .pr(16);
    display: inline-block;
    position: relative;
    text-decoration: none;
    .pb(24);
    @media (min-width: @breakpoints[xl]) {
      &:hover {
        span {
          .right(-4);
          .top(6);
        }
        &:before {
          width: 100%;
        }
      }
    }
    &-download {
      &:hover {
        span {
          .right(0);
          .top(16);
        }
      }
    }
    span {
      .h(8);
      .right(0);
      .top(10);
      .w(8);
      position: absolute;
      transition: all @easing100 linear;
      z-index: 3;
      img {
        vertical-align: top;
      }
    }
    &:before {
      content: '';
      width: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      .h(24);
      background: url('../images/waves/wave-underline.svg') center center repeat-x transparent;
      transition: width @easing250 linear;
    }
    &-download {
      .pr(24);
      span {
        .h(16);
        .w(16);
      }
    }
  }
  
  .ar-has-slide-arrow {
    &:hover {
      .ar-slide-arrow {
        width: 46px;
      }
    }
    .ar-slide-arrow {
      display: flex;
      overflow: hidden;
      position: relative;
      transition: width @easing100 linear;
      width: 36px;
      height: 12px;
      > img {
        .center-right();
        height: 12px;
        width: 46px;
      }
    }
  }
  .ar-carousel-mask {
    width: calc(100% - 30px);
    @media (min-width: @breakpoints[xl]) {
      position: relative;
      width: calc(100% - 70px);
    }
  }
  .ar-carousel-hidden {
    visibility: hidden;
    opacity: 0;
  }
  .ar-wavedown {
    &-dark {
      background: url('../images/waves/wave-down-dark.svg') top center repeat-x transparent;
      height: 198px;
    }
    &-white {
      background: url('../images/waves/wave-down-white.svg') top center repeat-x transparent;
      height: 198px;
    }
  }
  .ar-waveup {
    &-dark {
      background: url('../images/waves/wave-up-dark.svg') bottom center repeat-x transparent;
      height: 198px;
    }
    &-white {
      background: url('../images/waves/wave-up-white.svg') bottom center repeat-x transparent;
      height: 198px;
    }
  }
  .ar-svg-img {
    vertical-align: top;
  }
  .ar-height-100 {
    height: 100%;
  }
  .ar-width-100 {
    width: 100%;
  }
}