#ar2023 .ar-promise {
  &-grid {
    .pb(140);
    .pl(100);
    .pr(100);
    .pt(44);
  }
  &-col {
    .mb(0);
    &:first-child {
      width: 39.2%;
    }
    &:last-child {
      width: 60.2%;
    }
    h2 {
      width: 75%;
    }
  }
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &:last-child {
      min-height: 400px;
    }
  }
  &-wraptitle {
    .pb(64);
    width: 40%;
    p {
      .fs(36);
      .lh(40);
    }
  }
  &-item {
    margin: 0;
    &.ar-promise-active {
      .ar-promise-toggle {
        display: flex;
      }
      .ar-promise-desc {
        .pb(12);
        .pl(24);
        .pr(24);
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  &-toggle {
    width: calc(39.2% - 64px);
  }
  &-desc {
    width: calc(60.8% - 16px);
    position: absolute;
    top: 0;
    .right(8);
    height: 100%;
  }
  &-close {
    &:hover {
      img {
        transform: rotate(90deg);
      }
    }
  }
}