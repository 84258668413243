#ar2023 .ar-lead {
  &-col {
    &:first-child {
      display: block;
    }
    &:first-child {
      width: 39.8%;
    }
    &:last-child {
      width: 60.2%;
    }
    ul {
      .mt(32);
    }
    li {
      width: 20%;
      img {
        width: 85%;
      }
    }
  }
}