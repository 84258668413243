#ar2023 .ar-popup {
  &-mask {
    .pt(72);
    .pb(72);
    .pl(100);
    .pr(100);
  }
  &-desc {
    width: calc(100% - 200px);
    height: calc(100% - 200px);
  }
}