#ar2023 .ar-strategic {
  &-grid {
    .pl(64);
    .pr(64);
    &-business {
      .ar-strategic-col {
        h2, p {
          width: auto;
        }
      }
    }
  }
  &-col {
    width: 100%;
    h2 {
      width: 50%;
    }
    p {
      width: 50%;
    }
  }
  &-gridwide {
    .pl(32);
    .pr(32);
  }
  [data-carouzel-navigationwrapper] {
    display: none;
  }
}