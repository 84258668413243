#ar2023 .ar-leadership {
  &-grid {
    .pt(100);
    .pb(80);
    .pl(92);
    .pr(24);
  }
  &-col {
    width: 25%;
    &:first-child {
      width: 25%;
    }
    &:last-child {
      .ml(0);
      .mr(0);
      .pl(8);
      .pr(8);
      width: 75%;
    }
  }
  &-item {
    > button {
      &:hover {
        .ar-leadership-image img {
          width: 110%;
          height: 110%;
        }
      }
    }
  }
  &-top {
    .pb(24);
    .pl(24);
    .pr(24);
    .pt(48);
  }
  &-bottom {
    a {
      &:hover {
        span {
          .top(10);
        }
      }
    }
  }
  &-close {
    &:hover {
      img {
        transform: rotate(90deg);
      }
    }
  }
}