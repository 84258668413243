body.ar-popup-open {
  overflow: visible;
}
#ar2023 .ar-popup {
  &-mask {
    background-color: @colors[c3];
    padding: 0;
    position: absolute;
    width: calc(100% + 70px);
    &.ar-popup-active {
      z-index: 11;
    }
  }
  &-desc {
    height: 100%;
    width: 100%;
    &-nav {
      .bblr(16);
      bottom: 0;
      height: auto;
      left: 0;
      position: absolute;
      .pb(16);
      .pl(24);
      width: 31.3334%;
    }
    &-close:hover img {
      transform: rotate(90deg);
    }
    &-prev:hover img {
      right: 0px;
    }
    &-next:hover img {
      left: 0px;
    }
    &-content {
      .bblr(16);
      .btrr(16);
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow: hidden;
    }
    &-col {
      width: 33.3334%;
      .pt(24);
      .pl(24);
      .pr(24);
      .pb(45);
      .mb(0);
      height: 100%;
      overflow-y: auto;
      &.ar-popup-desc-img {
        .bblr(0);
        picture img {
          .center-left();
          left: 2px;
        }
        .ar-popup-desc-imgwrap {
          height: 100%;
          padding: 0;
        }
      }
      &:nth-child(1) {
        .btrr(16);
      }
      &:nth-child(2) {
        .pb(0);
        .pr(0);
        a {
          width: 75%;
          &:hover {
            &:after {
              .top(8);
            }
          }
        }
      }
      &:last-child {
        padding: 0;
        height: auto;
      }
    }
    &-imgmask {
      img {
        .center-left();
        height: 100%;
        width: auto;
        &.ar-on-xs {
          display: none;
        }
        &.ar-on-xl {
          display: block;
        }
      }
    }
    &-default {
      .ar-popup-desc-col {
        .pt(50);
        .pl(40);
        .pr(24);
        .pb(40);
        &.ar-popup-desc-img {
          padding: 0;
        }
      }
      .ar-popup-desc-nav {
        .pl(40);
      }
    }
  }
}
#ar2023 {
  #ar_supportpopup_2 {
    .ar-popup-desc-col:nth-child(2) {
      h5 {
        .fs(20);
        .lh(24);
      }
      p, ul, ol {
        .lh(20);
      }
    }
  }
}