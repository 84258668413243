#ar2023 .ar-support {
  &-grid {
    .pl(100);
    .pr(100);
  }
  &-rowwide {
    position: relative;
    .ar-popup-mask {
      width: 100%;
    }
  }
  &-colwide {
    .mb(0);
  }
  &-col {
    &:first-child {
      width: 39.2%;
    }
    &:last-child {
      width: 60.2%;
    }
    h2 {
      width: 75%;
    }
  }
  &-toggle {
    &:hover {
      img {
        height: 110%;
        width: 110%;
      }
    }
  }
}