#ar2023 .ar-places {
  &-grid {
    .pl(100);
    .pr(100);
    .pt(140);
  }
  &-col {
    &:first-child {
      width: 39.2%;
    }
    &:last-child {
      width: 60.2%;
    }
    h2 {
      width: 75%;
    }
  }
  &-rowwide {
    position: relative;
    .ar-popup-mask  {
      width: 100%;
      background-color: @colors[white];
    }
  }
  &-colwide {
    margin: 0;
    width: 25%;
  }
  &-toggle {
    &:hover {
      img {
        height: 110%;
        width: 110%;
      }
    }
  }
}