#ar2023 .ar-footer {
  background-color: @colors[c2];
  color: @colors[c3];
  &-bg {
    min-height: 100px;
    background: url('../images/waves/wave-footer.svg') 250px bottom repeat-x @colors[white];
    background-size: 50% auto;
  }
  &-grid {
    .mxw(1366);
    .pl(28);
    .pr(28);
    .pt(48);
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
  }
  &-col {
    width: 100%;
    .pl(8);
    .pr(8);
    .mb(32);
    h3 {
      margin: 0;
      .fontSemibold;
      .mb(16);
      .fs(20);
      .lh(27);
    }
    ul {
      display: block;
    }
    ul, li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      .mb(4);
      display: block;
    }
    address {
      .fontRegular;
      font-style: normal;
      .lh(19);
    }
    a {
      .fontRegular;
      .fs(16);
      .lh(18);
      color: @colors[c3];
      text-decoration: none;
      &.ar-footer-href {
        .fontBold;
      }
    }
    &.ar-footer-colwide {
      .fontRegular;
      li {
        display: inline-block;
        .pr(10);
        &:after {
          content: '/';
          .ml(10);
        }
        &:last-child:after {
          content: '';
          margin: 0;
        }
      }
    }
  }
  &-reg {
    .fontRegular;
    p {
      margin: 0;
    }
  }
  &-logo {
    display: block;
    .w(114);
    img {
      width: 100%;
    }
  }
}