#ar2023 .ar-places {
  &-grid {
    .pl(64);
    .pr(64);
  }
  &-col {
    width: 50%;
  }
  &-gridwide {
    .pl(32);
    .pr(32);
  }
  &-colwide {
    width: 50%;
  }
  h2 {
    max-width: 75%;
  }
}