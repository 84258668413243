#ar2023 .ar-lead {
  height: 770px;
  position: relative;
  &:after {
    background-color: @colors[c3];
    bottom: 0;
    content: '';
    display: block;
    height: 83px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &-bg {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    img {
      .center-left();
      height: 100%;
      width: auto;
    }
  }
  &-grid {
    .mxw(1366);
    .pl(20);
    .pr(20);
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
    flex-wrap: wrap;
  }
  &-col {
    width: 100%;
    &:first-child {
      display: none;
    }
    article {
      background-color: @colors[white];
      .btlr(16);
      .btrr(16);
      .bblr(16);
      .bbrr(16);
      .pt(32);
      .pr(32);
      .pb(32);
      .pl(32);
    }
    h2 {
      margin: 0;
      .mb(32);
      color: @colors[c3];
      .fs(36);
      .lh(40);
    }
    p {
      margin: 0;
      color: @colors[c3];
      .fs(18);
      .lh(22);
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      width: 33.3334%;
      img {
        vertical-align: top;
        width: 75%;
      }
    }
  }
}