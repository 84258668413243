#ar2023 .ar-download-stick {
  .bblr(32);
  .btlr(32);
  .pb(14);
  .pl(24);
  .pr(48);
  .pt(14);
  .w(150);
  background-color: @colors[c4];
  border: 0;
  color: @colors[c3];
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: left;
  top: 80%;
  transition: all @easing250 linear;
  z-index: 25;
  &:after {
    .h(64);
    .w(64);
    background: url('../images/icons/icon-download-primary.svg') center center no-repeat;
    background-size: 35%;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  h2 {
    .fontSemibold;
    .fs(16);
    .lh(18);
    margin: 0;
    padding: 0;
    transition: all @easing100 linear;
    transition-delay: @easing100;
  }
  &.ar-download-stick-collapsed {
    .w(72);
    h2 {
      transition: all @easing100 linear;
      transition-delay: 0;
      color: @colors[c4];
    }
  }
  &, &.ar-download-stick-collapsed {
    &:hover {
      .w(150);
      h2 {
        color: @colors[c3];
      }
    }
  }
  &.ar-download-stick-hidden {
    visibility: hidden;
    opacity: 0;
  }
}