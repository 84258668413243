#ar2023 .ar-strategic {
  .pt(120);
  &-grid {
    .pl(100);
    .pr(100);
    &-business {
      .mb(80);
      .ar-strategic-col {
        width: 50%;
        h2, p, ul {
          width: 90%;
        }
      }
      &-img {
        height: 0;
        overflow: hidden;
        padding-bottom: 117.622%;
        position: relative;
        width: 100%;
        img {
          height: 100%;
          left: 50%;
          object-fit: cover;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
    }
  }
  &-title {
    .pt(26);
    .pr(26);
    .pl(26);
    h3 {
      height: 65px;
    }
  }
  &-col {
    h2 {
      width: 39.8%;
    }
    p {
      width: 39.8%;
    }
  }
  &-gridwide {
    .pb(40);
  }
  &-toggle {
    &:hover {
      background-color: @colors[c2];
      .ar-strategic-image {
        img {
          width: 110%;
          height: 110%;
        }
      }
    }
  }
  [data-carouzel-previousarrow] {
    &:hover {
      img {
        right: 0;
      }
    }
  }
  [data-carouzel-nextarrow] {
    &:hover {
      img {
        left: 0;
      }
    }
  }
}