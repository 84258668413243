#ar2023 .ar-footer {
  &-bg {
    background-size: auto;
  }
  &-grid {

  }
  &-col {
    &:nth-child(1) {
      order: 0;
    }
    &:nth-child(2) {
      order: 4;
    }
    &:nth-child(3) {
      order: 1;
    }
    &:nth-child(4) {
      order: 2;
    }
    &:nth-child(5) {
      order: 3;
    }
    &:nth-child(6) {
      order: 5;
    }
    width: 25%;
    a:hover {
      text-decoration: underline;
    }
    &.ar-footer-colthin {
      display: block;
      width: 25%;
    }
    &.ar-footer-colwide {
      display: flex;
      width: 75%;
      justify-content: space-between;
      p {
        margin: 0;
        .ml(32);
      }
    }
  }
  &-logo {
    .w(144);
  }
  &-reg {
    max-width: 75%;
  }
}