#ar2023 .ar-nav {
  nav {
    border-bottom: solid transparent;
    height: 72px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 95;
    .bb(0);
    display: flex;
    justify-content: space-between;
    &.ar-nav-active {
      z-index: 135;
    }
    &.ar-nav-stuck {
      border-bottom: solid @colors[c3];
      .bb(8);
      background: @colors[white];
      a.ar-nav-logoxs, ul li:first-child {
        visibility: visible;
        opacity: 1;
      }
    }
    ul {
      .mxw(360);
      .pb(0);
      .pl(0);
      .pr(0);
      .pt(0);
      align-items: center;
      display: block;
      justify-content: center;
      list-style: none;
      margin: 0 auto;
      position: absolute;
      top: 72px;
      right: 8px;
      width: 100%;
      background-color: @colors[white];
      .btrr(16);
      .bblr(16);
    }
    li {
      display: block;
      margin: 0;
      padding: 0;
      border-bottom: solid @colors[c6];
      .bb(1);
      &:first-child {
        display: none;
        opacity: 0;
        visibility: hidden;
        transition: all @easing100 linear;
        .w(100);
        a {
          &:before {
            display: none;
          }
          img {
            vertical-align: top;
            width: 100%;
          }
        }
      }
      &:last-child {
        border-bottom: 0;
        a {
          text-align: left;
          .pr(32);
          &:before {
            display: none;
          }
          &:after {
            content: '';
            position: absolute;
            .top(0);
            .left(0);
            .w(1);
            height: 100%;
            display: none;
            background-color: @colors[c3];
          }
          span {
            .h(8);
            .right(16);
            .top(16);
            .w(8);
            position: absolute;
            transition: all @easing100 linear;
            z-index: 3;
          }
        }
      }
    }
    a {
      .fs(16);
      .h(64);
      .lh(16);
      .pb(2);
      .pl(16);
      .pr(16);
      .pt(2);
      align-items: center;
      color: @colors[c6];
      display: flex;
      font-size: round((16 / @rfs), 2) * 1rem;
      justify-content: center;
      line-height: round((16 / @rfs), 2) * 1rem;
      text-decoration: none;
      text-align: center;
      position: relative;
      &.ar-link-active {
        background-color: @colors[c1];
        color: @colors[white];
      }
      div {
        position: relative;
        z-index: 3;
      }
      &:before {
        .btlr(16);
        .btrr(16);
        background-color: @colors[c1];
        bottom: 0;
        content: '';
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        transition: height @easing100 linear;
        width: 100%;
        z-index: 1;
      }
    }
  }
  a.ar-nav-logoxs, &-togglexs {
    display: flex;
  }
  a.ar-nav-logoxs {
    opacity: 0;
    visibility: hidden;
    transition: all @easing100 linear;
    .w(100);
    img {
      width: 100%;
    }
    &:before {
      display: none;
    }
  }
  &-togglexs {
    .mt(10);
    .mr(10);
    .h(44);
    .w(44);
    background-color: @colors[white];
    border: 0;
    border-radius: 100%;
  }
  #ar_nav_navcheck {
    display: none;
    left: -999px;
    opacity: 0;
    position: absolute;
    top: -999px;
    &:checked {
      & + .ar-nav-navtoggle > span {
        transform: rotate(45deg);
        &:before {
          top: 0;
          transform: rotate(0deg);
        }
        &:after {
          top: 0;
          transform: rotate(90deg);
        }
      }
    }
  }
  &-navtoggle {
    .h(44);
    .w(44);
    cursor: pointer;
    position: relative;
    z-index: 1;
    background-color: @colors[white];
    border-radius: 100%;
    .mt(10);
    .mr(10);
    > span {
      &, &:before, &:after {
        background-color: @colors[c3];
        display: block;
        height: 2px;
        position: absolute;
        transition-duration: .25s;
      }
      & {
        margin-top: -1px;
        top: 50%;
        width: 20px;
        left: 50%;
        margin-left: -10px;
      }
      &:before {
        content: '';
        top: -6px;
        width: 100%;
      }
      &:after {
        content: '';
        top: 6px;
        width: 100%;
      }
    }
  }
  &-wrap {
    .pb(32);
    .pl(32);
    .pr(32);
    .pt(32);
    align-items: center;
    background-color: rgba(red(@colors[c3]), green(@colors[c3]), blue(@colors[c3]), 0.75);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    transition: all @easing250 linear;
    &.ar-nav-active {
      visibility: visible;
      opacity: 1;
    }
  }
}