#ar2023 .ar-places {
  color: @colors[c6];
  &-grid {
    .mxw(1366);
    .pt(124);
    .pl(36);
    .pr(36);
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
  }
  &-col {
    .mb(36);
    width: 100%;
    h2 {
      margin: 0;
      .mb(32);
      .fs(36);
      .lh(40);
    }
    p {
      margin: 0;
      .mb(32);
      .fs(18);
      .lh(22);
    }
    .ar-section-link {
      color: @colors[c6]; 
    }
  }
  &-gridwide {
    .mxw(1366);
    .pl(36);
    .pr(36);
    margin: 0 auto;
    width: 100%;
  }
  &-rowwide {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;
    .ml(-8);
    .mr(-8);
    .mb(50);
  }
  &-colwide {
    width: 100%;
    .pl(8);
    .pr(8);
    .mb(40);
  }
  &-toggle {
    .bblr(16);
    .btrr(16);
    background-color: @colors[c3];
    border: 0;
    cursor: pointer;
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 145.25%;
    position: relative;
    width: 100%;
    text-align: left;
    picture > img {
      .center-center();
      height: 100.1%;
      width: 100.1%;
      object-fit: cover;
      z-index: 1;
      transition: all @easing500 ease;
    }
    &:before {
      background: linear-gradient(to bottom, rgba(10,71,114,0) 0%,rgba(10,71,114,0.63) 60%,rgba(5,36,57,0.73) 100%);
      content: '';
      height: 50.5%;
      left: 0;
      position: absolute;
      bottom: -2px;
      width: 100.1%;
      z-index: 2;
    }
  }
  &-title {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 3;
    .pl(36);
    .pb(30);
    .pr(36);
    .pt(30);
    h3 {
      .fontSemibold;
      .fs(23);
      .lh(27);
      color: @colors[white];
      margin: 0;
      .mb(26);
    }
    .ar-slide-arrow {
      color: @colors[white];
    }
  }
}