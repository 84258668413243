@import (reference) './config/_variables.less';
@import (reference) './config/_mixins.less';
@import './config/_fonts.less';
@import './config/_normalize.less';
@import './config/_base.less';
@import './config/_carouzel.less';

@import './components/header/_default.less';
@import './components/download-stick/_default.less';
@import './components/banner2/_default.less';
@import './components/navigation/_default.less';
@import './components/leading/_default.less';
@import './components/strategic/_default.less';
@import './components/glance/_default.less';
@import './components/leadership/_default.less';
@import './components/places/_default.less';
@import './components/promise/_default.less';
@import './components/support/_default.less';
@import './components/downloads/_default.less';
@import './components/footer/_default.less';
@import './components/popups/_default.less';
@import './components/disclaimer/_default.less';

@media (min-width: @breakpoints[md]) {
  @import './components/banner2/_md.less';
  @import './components/navigation/_md.less';
  @import './components/leading/_md.less';
  @import './components/strategic/_md.less';
  @import './components/glance/_md.less';
  @import './components/leadership/_md.less';
  @import './components/places/_md.less';
  @import './components/promise/_md.less';
  @import './components/support/_md.less';
  @import './components/downloads/_md.less';
  @import './components/footer/_md.less';
  @import './components/popups/_md.less';
  @import './components/disclaimer/_md.less';
}
@media (min-width: @breakpoints[xl]) {
  @import './components/banner2/_xl.less';
  @import './components/download-stick/_xl.less';
  @import './components/navigation/_xl.less';
  @import './components/leading/_xl.less';
  @import './components/strategic/_xl.less';
  @import './components/glance/_xl.less';
  @import './components/leadership/_xl.less';
  @import './components/places/_xl.less';
  @import './components/promise/_xl.less';
  @import './components/support/_xl.less';
  @import './components/downloads/_xl.less';
  @import './components/footer/_xl.less';
  @import './components/popups/_xl.less';
  @import './components/disclaimer/_xl.less';
}