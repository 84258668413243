#ar2023 .ar-promise {
  color: @colors[c6];
  &-grid {
    .mxw(1366);
    .pt(64);
    .pl(36);
    .pr(36);
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
  }
  &-col {
    .mb(40);
    width: 100%;
    h2 {
      margin: 0;
      .mb(32);
      .fs(36);
      .lh(40);
    }
    p {
      margin: 0;
      .mb(30);
      .fs(18);
      .lh(22);
      &:last-child {
        margin: 0;
      }
    }
    .ar-section-link {
      color: @colors[c6]; 
    }
  }
  &-gridwide {
    .mxw(1366);
    .pl(36);
    .pr(36);
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 4;
  }
  &-rowwide {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;
    .ml(-8);
    .mr(-8);
  }
  &-colwide {
    width: 100%;
    .pl(8);
    .pr(8);
    .pt(48);
    .pb(48);
  }
  &-bg {
    position: relative;
    overflow: hidden;
    picture > img {
      .center-center();
      height: 100.1%;
      width: 100.1%;
      object-fit: cover;
      z-index: 1;
    }
  }
  &-wave {
    position: relative;
    z-index: 2;
    background-color: transparent;
    &.ar-wavedown-white {
      background-position: 400px 0;
    }
    &.ar-waveup-dark {
      background-position: -800px 0;
    }
  }
  &-toggle {
    .bblr(16);
    .btrr(16);
    .fontMedium;
    .fs(18);
    .lh(22);
    .pb(28);
    .pl(18);
    .pr(80);
    .pt(28);
    align-items: flex-start;
    border: 0;
    color: @colors[c6];
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    opacity: 1;
    position: relative;
    text-align: left;
    transition: opacity @easing100 linear, visibility @easing100 linear;
    visibility: visible;
    width: 100%;
    .ar-slide-arrow {
      position: absolute;
      left: calc(100% - 65px);
      .top(35);
    }
  }
  &-item {
    .mt(24);
    .mb(24);
    &:nth-child(1) {
      .ar-promise-toggle, .ar-promise-desc {
        background-color: @colors[c1];
      }
    }
    &:nth-child(2) {
      .ar-promise-toggle, .ar-promise-desc {
        background-color: @colors[c2];
      }
    }
    &:nth-child(3) {
      .ar-promise-toggle, .ar-promise-desc {
        background-color: @colors[c7];
      }
    }
    &.ar-promise-active {
      .ar-promise-toggle {
        display: none;
      }
      .ar-promise-desc {
        .pb(24);
        .pl(18);
        .pr(18);
        .pt(24);
        opacity: 1;
        visibility: visible;
        height: auto;
      }
    }
  }
  &-desc {
    .bblr(16);
    .btrr(16);
    height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: opacity @easing100 linear, visibility @easing100 linear;
    visibility: hidden;
    h3 {
      .fontSemibold;
      margin: 0;
      .mb(24);
      .fs(28);
      .lh(34);
    }
    h4 {
      .fontMedium;
      margin: 0;
      .mb(16);
      .fs(18);
      .lh(22);
    }
    p.ar-promise-intro {
      .fs(18);
      .lh(22);
    }
  }
  &-close {
    .h(12);
    .w(12);
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: absolute;
    .top(24);
    .right(24);
    img {
      transition: transform @easing100 linear;
    }
  }
  &-wraptitle {
    .pb(48);
    p {
      .fs(32);
      .lh(36);
      margin: 0;
      .fontBold;
      color: @colors[white];
    }
  }
}