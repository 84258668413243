#ar2023 .ar-leadership {
  &-grid {
    .pl(64);
    .pr(64);
  }
  &-top {
    .pb(10);
    .pl(10);
    .pr(24);
    .pt(20);
  }
  &-col {
    .mb(0);
    &:first-child {
      width: 100%;
    }
    &:last-child {
      .ml(-64);
      .mr(-64);
      .pl(32);
      .pr(32);
      width: calc(100% + 128px);
    }
  }
  &-colwide {
    width: 33.3334%;
    .mb(0);
  }
  h2 {
    max-width: 75%;
  }
}