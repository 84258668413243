#ar2023 .ar-glance {
  color: @colors[c6];
  &-grid {
    .mxw(1366);
    .pl(36);
    .pr(36);
    .pt(80);
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
  }
  &-col {
    width: 100%;
  }
  h2 {
    margin: 0;
    .mb(32);
    .fs(36);
    .lh(40);
  }
  &-statgrid {
    .ml(-20);
    .mr(-20);
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &-statcol {
    width: 50%;
    .pl(20);
    .pr(20);
    .mb(26);
  }
  &-stat {
    position: relative;
  }
  &-val, &-title {
    .fontBold;
    .fs(36);
    .lh(42);
    display: block;
    font-style: normal;
    .mt(0);
    .mr(0);
    .ml(0);
    .mb(16);
  }
  &-desc {
    .fs(18);
    .lh(25);
    display: block;
    font-style: normal;
    margin: 0;
  }
  &-last {
    .fs(14);
    .lh(28);
    display: block;
    font-style: normal;
    margin: 0;
  }
  &-info {
    margin: 0;
    position: absolute;
    left: 0;
    .top(-10);
  }
  &-inner {
    &-grid {
      display: flex;
      border-top: 1px solid @colors[c2];
      .pt(10);
      .pb(10);
    }
    &-col {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      &:last-child {
        .pl(20);
        .ar-glance-val {
          .fs(40);
          .lh(40);
          margin: 0;
        }
      }
    }
  }
}