.fontLight {
  font-family: 'Calibre web', sans-serif;
  font-weight: 300;
}
.fontRegular {
  font-family: 'Calibre web', sans-serif;
  font-weight: 400;
}
.fontMedium {
  font-family: 'Calibre web', sans-serif;
  font-weight: 500;
}
.fontSemibold {
  font-family: 'Calibre web', sans-serif;
  font-weight: 600;
}
.fontBold {
  font-family: 'Calibre web', sans-serif;
  font-weight: 700;
}
.top-left() {
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(0, 0);
}
.top-center() {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
}
.top-right() {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, 0);
}
.center-left() {
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.center-center() {
    left: 50%;
    position: absolute;
    background: red;
    top: 50%;
    transform: translate(-50%, -50%);
}
.center-right() {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.bottom-left() {
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 0);
}
.bottom-center() {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}
.bottom-right() {
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(0, 0);
}
.pt(@val) {
  padding-top: round((@val / @rfs), 2) * 1rem;
}
.pr(@val) {
  padding-right: round((@val / @rfs), 2) * 1rem;
}
.pb(@val) {
  padding-bottom: round((@val / @rfs), 2) * 1rem;
}
.pl(@val) {
  padding-left: round((@val / @rfs), 2) * 1rem;
}
.mt(@val) {
  margin-top: round((@val / @rfs), 2) * 1rem;
}
.mr(@val) {
  margin-right: round((@val / @rfs), 2) * 1rem;
}
.mb(@val) {
  margin-bottom: round((@val / @rfs), 2) * 1rem;
}
.ml(@val) {
  margin-left: round((@val / @rfs), 2) * 1rem;
}
.fs(@val) {
  font-size: round((@val / @rfs), 2) * 1rem;
}
.lh(@val) {
  line-height: round((@val / @rfs), 2) * 1rem;
}
.bt(@val) {
  border-top-width: round((@val / @rfs), 2) * 1rem;
}
.br(@val) {
  border-right-width: round((@val / @rfs), 2) * 1rem;
}
.bb(@val) {
  border-bottom-width: round((@val / @rfs), 2) * 1rem;
}
.btlr(@val) {
  border-top-left-radius: round((@val / @rfs), 2) * 1rem;
}
.btrr(@val) {
  border-top-right-radius: round((@val / @rfs), 2) * 1rem;
}
.bbrr(@val) {
  border-bottom-right-radius: round((@val / @rfs), 2) * 1rem;
}
.bblr(@val) {
  border-bottom-left-radius: round((@val / @rfs), 2) * 1rem;
}
.bl(@val) {
  border-left-width: round((@val / @rfs), 2) * 1rem;
}
.w(@val) {
  width: round((@val / @rfs), 2) * 1rem;
}
.mxw(@val) {
  max-width: round((@val / @rfs), 2) * 1rem;
}
.mnw(@val) {
  min-width: round((@val / @rfs), 2) * 1rem;
}
.h(@val) {
  height: round((@val / @rfs), 2) * 1rem;
}
.mxh(@val) {
  max-height: round((@val / @rfs), 2) * 1rem;
}
.mnh(@val) {
  min-height: round((@val / @rfs), 2) * 1rem;
}
.top(@val) {
  top: round((@val / @rfs), 2) * 1rem;
}
.right(@val) {
  right: round((@val / @rfs), 2) * 1rem;
}
.bottom(@val) {
  bottom: round((@val / @rfs), 2) * 1rem;
}
.left(@val) {
  left: round((@val / @rfs), 2) * 1rem;
}