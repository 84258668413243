#ar2023 .ar-promise {
  &-grid {
    .pl(64);
    .pr(64);
  }
  &-toggle {
    width: calc(50% - 32px);
  }
  // &-item {
  //   &.ar-promise-active {
  //     .ar-promise-toggle {
  //       display: flex;
  //       .bblr(0);
  //     }
  //   }
  // }
  &-colwide {
    .pt(80);
    .pb(80);
  }
}
// #ar2023 .ar-promise {
//   &-grid {
//     .pl(64);
//     .pr(64);
//   }
//   &-col {
//     width: 50%;
//     margin: 0;
//   }
//   &-colwide {
//     .pt(80);
//     .pb(80);
//   }
//   &-wrap {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     min-height: 600px;
//     position: relative;
//   }
//   &-toggle {
//     width: calc(50% - 32px);
//   }
//   &-item {
//     .mt(11);
//     .mb(11);
//     &.ar-promise-active {
//       .ar-promise-toggle {
//         display: flex;
//         .pb(28);
//         .pl(18);
//         .pr(80);
//         .pt(28);
//         opacity: 1;
//         visibility: visible;
//       }
//       .ar-promise-desc {
//         .pb(28);
//         .pl(18);
//         .pr(18);
//         .pt(64);
//         height: calc(100% - 22px);
//       }
//     }
//   }
//   &-desc {
//     .pb(28);
//     .pl(18);
//     .pr(18);
//     .pt(64);
//     position: absolute;
//     top: 11px;
//     .right(8);
//     width: calc(50% - 16px);
//     height: calc(100% - 22px);
//   }
//   h2 {
//     max-width: 75%;
//   }
// }
