#ar2023 .ar-lead {
  &-grid {
    .pl(100);
    .pr(100);
  }
  &-col {
    article {
      .pt(50);
      .pr(50);
      .pb(50);
      .pl(50);
    }
    li {
      img {
        width: 65%;
      }
    }
  }
  &-logobg {
    z-index: 99;
  }
}