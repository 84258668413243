#ar2023 .ar-glance {
  .pt(0);
  &-grid {
    .pl(100);
    .pr(100);
    .pt(140);
    .pb(80);
  }
  &-col {
    &:first-child {
      width: 39.8%;
    }
    &:last-child {
      width: 60.2%;
    }
  }
  &-statcol {
    .mb(38);
  }
  &-val, &-title {
    .fs(60);
    .lh(68);
    .mb(20);
  }
  &-desc {
    .fs(18);
    .lh(25);
  }
}