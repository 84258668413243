#ar2023 .ar-banner {
  &-textwrap {
    z-index: 3;
    .pl(100);
    .pr(100);
    > div {
      .mxw(1366);
      .pt(50);
      .pl(50);
      .pr(50);
      .pb(50);
    }
  }
  &-text {
    h1 {
      .fs(68);
      .lh(68);
    }
    p {
      &.ar-banner-intro {
        .fs(30);
        .lh(36);
      }
      .fs(20);
      .lh(27);
    }
  }
}