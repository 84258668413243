[data-carouzel], [data-carouzel]:after, [data-carouzel]:before,
[data-carouzel] *,
[data-carouzel] *:after,
[data-carouzel] *:before {
  box-sizing: border-box;
}
[data-carouzel] button:not(:disabled),
[data-carouzel] [type=button]:not(:disabled),
[data-carouzel] [type=reset]:not(:disabled),
[data-carouzel] [type=submit]:not(:disabled) {
  cursor: pointer;
}
[data-carouzel] .__carouzel-hidden {
  display: none !important;
}
[data-carouzel] [data-carouzel-trackwrapper] {
  position: relative;
}
[data-carouzel] [data-carouzel-trackouter] {
  width: 100%;
}
[data-carouzel] [data-carouzel-trackmask] {
  overflow: hidden;
  width: 100%;
}
[data-carouzel] [data-carouzel-track] {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 1px;
  position: relative;
  transform-style: preserve-3d;
  width: 100%;
}
[data-carouzel] [data-carouzel-slide] {
  border: 1px solid transparent;
  width: 100%;
}
[data-carouzel] [data-carouzel-slide] img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
[data-carouzel] [data-carouzel-previousarrow],
[data-carouzel] [data-carouzel-nextarrow],
[data-carouzel] [data-carouzel-pause],
[data-carouzel] [data-carouzel-play] {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #ced4da;
  color: transparent;
  cursor: pointer;
  font-size: 1px;
  height: 40px;
  padding: 0;
  transition: all 250ms ease;
  width: 40px;
  z-index: 1;
}
[data-carouzel] [data-carouzel-previousarrow] svg,
[data-carouzel] [data-carouzel-nextarrow] svg,
[data-carouzel] [data-carouzel-pause] svg,
[data-carouzel] [data-carouzel-play] svg {
  cursor: pointer;
  height: 75%;
  width: 75%;
  fill: #212529;
}
[data-carouzel] [data-carouzel-previousarrow]:hover, [data-carouzel] [data-carouzel-previousarrow]:focus,
[data-carouzel] [data-carouzel-nextarrow]:hover,
[data-carouzel] [data-carouzel-nextarrow]:focus,
[data-carouzel] [data-carouzel-pause]:hover,
[data-carouzel] [data-carouzel-pause]:focus,
[data-carouzel] [data-carouzel-play]:hover,
[data-carouzel] [data-carouzel-play]:focus {
  background-color: #ced4da;
  opacity: 1;
}
[data-carouzel] [data-carouzel-previousarrow].__carouzel-disabled,
[data-carouzel] [data-carouzel-nextarrow].__carouzel-disabled,
[data-carouzel] [data-carouzel-pause].__carouzel-disabled,
[data-carouzel] [data-carouzel-play].__carouzel-disabled {
  cursor: not-allowed;
  opacity: 0.25;
  pointer-events: none;
  outline: none;
}
[data-carouzel] [data-carouzel-previousarrow].__carouzel-disabled svg,
[data-carouzel] [data-carouzel-nextarrow].__carouzel-disabled svg,
[data-carouzel] [data-carouzel-pause].__carouzel-disabled svg,
[data-carouzel] [data-carouzel-play].__carouzel-disabled svg {
  cursor: not-allowed;
}
[data-carouzel] [data-carouzel-pause],
[data-carouzel] [data-carouzel-play] {
  border-radius: 100%;
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
}
[data-carouzel] [data-carouzel-previousarrow],
[data-carouzel] [data-carouzel-nextarrow] {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
[data-carouzel] [data-carouzel-previousarrow] {
  left: -48px;
}
[data-carouzel] [data-carouzel-nextarrow] {
  right: -48px;
}
[data-carouzel] [data-carouzel-navigation] {
  flex-wrap: wrap;
}
[data-carouzel] [data-carouzel-navigation],
[data-carouzel] [data-carouzel-pageinfo] {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
[data-carouzel] [data-carouzel-navigationwrapper],
[data-carouzel] [data-carouzel-pageinfo] {
  margin: 1em 0;
}
[data-carouzel] [data-carouzel-dot] {
  display: inline-flex;
  margin: 2.5px;
}
[data-carouzel] [data-carouzel-dot] > button {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #ced4da;
  color: #212529;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  line-height: 31px;
  margin: 0;
  padding: 0;
  transition: background-color 250ms ease;
  width: 32px;
}
[data-carouzel] [data-carouzel-dot] > button:hover, [data-carouzel] [data-carouzel-dot] > button:focus {
  background-color: #dee2e6;
}
[data-carouzel] [data-carouzel-dot].__carouzel-pagetitle {
  display: block;
  margin: 0;
  width: 100%;
}
[data-carouzel] [data-carouzel-dot].__carouzel-pagetitle > button {
  border-radius: 0;
  height: auto;
  line-height: normal;
  margin: 0;
  padding: 10px;
  text-align: left;
  width: 100%;
}
[data-carouzel] [data-carouzel-dot].__carouzel-active > button {
  background-color: #ced4da;
}
[data-carouzel] [data-carouzel-pageinfo] {
  font-weight: bold;
}
[data-carouzel] [data-carouzel-currentpage],
[data-carouzel] [data-carouzel-totalpages] {
  display: inline-block;
  padding: 0 0.5em;
  vertical-align: top;
}
[data-carouzel][data-carouzel-centered] [data-carouzel-trackmask] {
  display: flex;
  justify-content: center;
  align-items: center;
}
[data-carouzel][data-carouzel-rtl] [data-carouzel-track] {
  flex-direction: row-reverse;
}
[data-carouzel][data-carouzel-rtl] [data-carouzel-navigation] {
  flex-direction: row-reverse;
}
[data-carouzel][data-carouzel-hasscrollbar] [data-carouzel-trackouter] {
  overflow: auto;
}
[data-carouzel][data-carouzel-hasscrollbar] [data-carouzel-scrollbarwrapper] {
  margin: 1em 0;
}
[data-carouzel][data-carouzel-hasscrollbar] [data-carouzel-scrollbartrack] {
  border: 1px solid transparent;
  height: 10px;
  position: relative;
  z-index: 1;
}
[data-carouzel][data-carouzel-hasscrollbar] [data-carouzel-track] {
  padding-bottom: 15px;
}
[data-carouzel][data-carouzel-hasscrollbar] [data-carouzel-scrollbarthumb] {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 0;
  height: 40px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 0;
  z-index: 2;
}
[data-carouzel] [data-carouzel-video] {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
[data-carouzel] [data-carouzel-video] iframe,
[data-carouzel] [data-carouzel-video] video,
[data-carouzel] [data-carouzel-video] .video-js {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
[data-carouzel] [data-carouzel-video] .vjs-loading-spinner,
[data-carouzel] [data-carouzel-video] .vjs-big-play-button,
[data-carouzel] [data-carouzel-video] .vjs-control-bar,
[data-carouzel] [data-carouzel-video] .vjs-error-display,
[data-carouzel] [data-carouzel-video] .vjs-modal-dialog {
  z-index: 3;
}
[data-carouzel] [data-carouzel-video] [data-carouzel-video-content] {
  background-color: rgba(255, 255, 255, 0.85);
  bottom: 0;
  left: 0;
  padding: 1em;
  position: absolute;
  width: 100%;
  z-index: 5;
}
[data-carouzel][data-carouzel-vertical] {
  padding: 48px 0;
}
[data-carouzel][data-carouzel-vertical] [data-carouzel-track] {
  flex-direction: column;
}
[data-carouzel][data-carouzel-vertical] [data-carouzel-previousarrow],
[data-carouzel][data-carouzel-vertical] [data-carouzel-nextarrow] {
  position: absolute;
  top: auto;
  transform: translate(-50%, 0);
  left: 50%;
}
[data-carouzel][data-carouzel-vertical] [data-carouzel-previousarrow] {
  top: -48px;
}
[data-carouzel][data-carouzel-vertical] [data-carouzel-nextarrow] {
  bottom: -48px;
}
[data-carouzel][data-carouzel-vertical] [data-carouzel-pageinfo] {
  margin: 4em 0 1em 0;
}
[data-carouzel].__carouzel-editmode [data-carouzel-trackouter] {
  width: 100% !important;
}
[data-carouzel].__carouzel-editmode [data-carouzel-track] {
  display: block;
  transform: translate3d(0, 0, 0) !important;
  width: 100% !important;
}
[data-carouzel].__carouzel-editmode [data-carouzel-track] [data-carouzel-slide] {
  width: 100% !important;
}