#ar2023 .ar-nav {
  height: 72px;
  nav {
    .bb(8);
    background: @colors[white];
    border-bottom: solid @colors[c3];
    display: block;
    position: static;
    .bb(8);
    ul {
      position: relative;
      top: auto;
      right: auto; 
      .mxw(1366);
      display: flex;
      li {
        display: block;
        margin: 0;
        padding: 0;
        border: 0;
        &:first-child {
          display: block;
        }
        &:last-child {
          .w(100);
          a {
            &:after {
              display: block;
            }
            &:hover {
              color: @colors[c6];
              span {
                .top(13);
                .right(13);
              }
            }
          }
        }
      }
    }
    &.ar-nav-stuck {
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 95;
    }
  }
  a {
    &:hover {
      color: @colors[white];
      &:before {
        height: calc(100% + 15px);
      }
    }
  }
  a.ar-nav-logoxs, &-navtoggle {
    display: none;
  }
  &-wrap {
    position: static;
    padding: 0;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
  }
}