#ar2023 .ar-strategic {
  background-color: @colors[c3];
  color: @colors[white];
  .pt(120);
  &-grid {
    .mxw(1366);
    .pl(36);
    .pr(36);
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    &-business {
      flex-wrap: wrap;
      .ar-strategic-col {
        .mb(80);
        width: 100%;
        a {
          color: @colors[c1];
          text-decoration: underline;
        }
        p {
          .mb(16);
        }
      }
      &-img {
        img {
          width: 100%;
        }
      }
  }
  &-col {
    .mb(40);
    width: 100%;
    }
    h2 {
      margin: 0;
      .mb(32);
      .fs(36);
      .lh(40);
    }
    p {
      margin: 0;
      .fs(18);
      .lh(22);
      &.ar-strategic-intro {
        .fs(24);
        .lh(28);
      }
    }
    .ar-section-link {
      color: @colors[white];
    }
  }
  &-gridwide {
    .mxw(1366);
    .pl(36);
    .pr(36);
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }
  &-colwide {
    overflow: hidden;
    width: 100%;
  }
  [data-carouzel] {
    [data-carouzel-dot] {
      margin: 0 5px;
      > button {
        .h(9);
        .w(9);
        background-color: @colors[c6];
        border: 0;
        color: transparent;
        font-size: 0px;
      }
      &.__carouzel-active {
        > button {
          background-color: @colors[white];
        }
      }
    }
  }
  [data-carouzel-previousarrow], [data-carouzel-nextarrow] {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    height: 18px;
    left: auto;
    overflow: hidden;
    position: relative;
    right: auto;
    width: 46px;
    transform: none;
    img {
      position: absolute;
      height: 18px;
      width: 46px;
      top: 0;
    }
  }
  [data-carouzel-previousarrow] {
    img {
      right: -10px;
      transition: right @easing100 linear;
    }
  }
  [data-carouzel-nextarrow] {
    img {
      left: -10px;
      transition: left @easing100 linear;
    }
  }
  [data-carouzel-navigationwrapper] {
    margin: 2em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  [data-carouzel-navigation] {
    width: auto;
    .ml(24);
    .mr(24);
  }
  [data-carouzel-trackmask] {
    overflow: visible;
  }
  [data-carouzel-slide] {
    height: 578px;
    overflow: hidden;
    &:first-child {
      .btlr(16);
      .bblr(16);
    }
    &:last-child {
      .btrr(16);
      .bbrr(16);
    }
  }
  &-toggle {
    background-color: @colors[white];
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all @easing250 linear;
    width: 100%;
  }
  &-title {
    .pt(16);
    .pr(16);
    .pl(16);
    color: @colors[c6];
    height: 137px;
    position: relative;
    text-align: left;
    h3 {
      margin: 0;
      .mb(10);
      height: 75px;
      .fontMedium;
      .fs(18);
      .lh(22);
      color: @colors[c6];;
    }
  }
  &-image {
    position: relative;
    height: 480px;
    overflow: hidden;
    img {
      transition: all @easing500 ease;
      .center-center();
      height: 100.1%;
      width: 100.1%;
      object-fit: cover;
    }
  }
}