#ar2023 .ar-banner {
  color: @colors[white];
  position: relative;
  overflow: hidden;
  &-bg, &-textwrap, &-bgmask {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &-bg {
    overflow: hidden;
    img {
      .bottom-center();
      height: 100.1%;
      width: 100.1%;
      object-fit: cover;
    }
  }
  &-bgmask {
    .center-center();
    left: 60%;
    width: 50%;
    display: none;
    img {
      .center-right();
      height: 100.1%;
      width: auto;
    }
  }
  &-textwrap {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    z-index: 4;
    .pl(20);
    .pr(20);
    > div {
      // background-color: rgba(red(@colors[white]), blue(@colors[white]), green(@colors[white]), 0.75);
      .mxw(1366);
      .pt(36);
      .pb(36);
      .pl(36);
      .pr(36);
      .btlr(16);
      .btrr(16);
      .bblr(16);
      .bbrr(16);
    }
  }
  &-text {
    h1 {
      .fontBold;
      .fs(42);
      .lh(46);
      margin: 0;
      .mb(14);
    }
    p {
      .fs(16);
      .lh(18);
      margin: 0;
      &.ar-banner-intro {
        .fontLight;
        .fs(20);
        .lh(24);
        .mb(24);
      }
    }
  }
  [data-carouzel] {
    .center-center();
    width: 100%;
    height: 100%;
  }
  [data-carouzel-slide] {
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  &-waves {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    // background-color: var(--color);
    // box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
    transition: 1s;
    &:before, &:after {
      content: '';
      position: absolute;
      width: 275vw;
      height: 300vw;
      top: -50vw;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      border-radius: 44%;
      background: rgba(red(@colors[c3]), green(@colors[c3]), blue(@colors[c3]), 0.35);
      animation: arwaves 16s linear infinite;
    }
    &:after {
      border-radius: 44%;
      background: rgba(red(@colors[c6]), green(@colors[c6]), blue(@colors[c6]), 0.35);
      animation: arwaves 32s linear infinite;
    }
  }
}

@keyframes arwaves {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}
