#ar2023 .ar-leadership {
  background-color: @colors[c3];
  color: @colors[white];
  blockquote {
    margin: 0;
  }
  &-grid {
    .mxw(1366);
    .pl(28);
    .pr(28);
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
  &-col {
    width: 100%;
    .pl(8);
    .pr(8);
    h2 {
      margin: 0;
      .mb(32);
      .fs(36);
      .lh(40);
    }
    p {
      margin: 0;
      .mb(32);
      .fs(18);
      .lh(22);
    }
    .ar-section-link {
      color: @colors[white];
      .mb(32);
    }
  }
  &-item {
    position: relative;
    > button {
      .bblr(16);
      .btrr(16);
      background-color: @colors[white];
      border: 0;
      color: @colors[black];
      cursor: pointer;
      height: 400px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-align: left;
      transition: all @easing250 linear;
      width: 100%;
    }
    &.ar-leadership-active {
      .ar-leadership-desc {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-image {
    height: 244px;
    overflow: hidden;
    position: relative;
    img {
      .center-center();
      height: 100%;
      object-fit: cover;
      transition: all @easing500 ease;
      width: 100%;
    }
  }
  &-title {
    .fs(18);
    .lh(25);
    .pb(40);
    .pl(20);
    .pr(20);
    .pt(30);
    height: 156px;
    position: relative;
    em {
      .fontBold;
      display: block;
      font-style: normal;
    }
    .ar-slide-arrow {
      position: absolute;
      .left(20);
      .bottom(30);
    }
  }
  &-gridwide {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .ml(-8);
    .mr(-8);
  }
  &-colwide {
    width: 100%;
    .mb(40);
    .pl(8);
    .pr(8);
  }
  &-desc {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all @easing100 linear;
    visibility: hidden;
    width: 100%;
    z-index: 2;
  }
  &-top {
    .bblr(16);
    .btrr(16);
    .pb(24);
    .pl(24);
    .pr(24);
    .pt(60);
    background-color: @colors[c2];
    color: @colors[black];
    height: 312px;
    position: relative;
    z-index: 4;
    em {
      display: block;
      font-style: normal;
      .fontSemibold;
      .fs(22);
      .lh(24);
    }
    span {
      display: block;
      .fs(18);
      .lh(24);
      &.ar-leadership-quot {
        display: flex;
        .fs(24);
        .fontBold;
      }
    }
    p {
      .mt(16);
      .fs(16);
      .lh(20);
    }
  }
  &-bottom {
    .bblr(16);
    .pb(24);
    .pl(24);
    .pr(24);
    .pt(32);
    background-color: @colors[white];
    color: @colors[black];
    height: 98px;
    position: relative;
    top: -10px;
    z-index: 3;
    a {
      color: inherit;
      text-decoration: none;
      .fs(16);
      .lh(21);
      span {
        display: inline-block;
        vertical-align: top;
        position: relative;
        .ml(10);
        .w(11.25);
        .h(11.25);
        .top(4);
        transition: top @easing100 linear;
      }
    }
  }
  &-close {
    .h(12);
    .w(12);
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 5;
    img {
      transition: transform @easing100 linear;
    }
  }
}