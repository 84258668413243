#ar2023 .ar-header {
  position: relative;
  z-index: 101;
  &-logo {
    position: absolute;
    .mt(32);
    .ml(32);
    > a {
      display: block;
      .w(102);
      img {
        vertical-align: top;
        width: 100%;
      }
    }
  }
  &-logobg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    .w(237);
  }
}