body.ar-popup-open {
  overflow: hidden;
}

#ar2023 .ar-popup {
  &-mask {
    .pb(20);
    .pl(20);
    .pr(20);
    .pt(20);
    background-color: rgba(red(@colors[c3]), green(@colors[c3]), blue(@colors[c3]), 0.75);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity @easing100 linear, visibility @easing100 linear;
    &.ar-popup-active {
      z-index: 200;
      opacity: 1;
      visibility: visible;
    }
  }
  &-desc {
    .center-center();
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    .btrr(16);
    .bblr(16);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity @easing100 linear, visibility @easing100 linear;
    &.ar-popup-active {
      z-index: 202;
      opacity: 1;
      visibility: visible;
    }
    &-nav {
      height: 44px;
      padding: 16px 24px 16px 24px;
      .btrr(16);
      > button {
        height: 12px;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        .mr(32);
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
      }
    }
    &-prev, &-next {
      width: 46px;
      position: relative;
      img {
        width: 46px;
        height: 12px;
        position: absolute;
        top: 0;
      }
    }
    &-close, &-prev, &-next {
      cursor: pointer;
      img {
        transition: all @easing100 linear;
      }
    }
    &-close {
      width: 12px;
    }
    &-prev img {
      right: -10px;
    }
    &-next img {
      left: -10px;
    }
    &-col {
      // .mb(20);
      .pt(20);
      .pr(20);
      .pl(20);
      .pb(20);
      &.ar-popup-desc-img {
        .mb(0);
        .pt(0);
        .pr(0);
        .pl(0);
        .pb(0);
        .bblr(16);
        position: relative;
        .ar-popup-desc-imgwrap {
          height: 0;
          overflow: hidden;
          padding-bottom: 75.862%;
          position: relative;
        }
        picture img {
          .top-center();
          height: 100.1%;
          width: 100.1%;
          object-fit: cover;
          top: 2px;
        }
      }
    }
    h4, h5, h6 {
      .fontSemibold;
    }
    h4 {
      margin: 0;
      .mb(16);
      .fs(28);
      .lh(32);
    }
    h5 {
      margin: 0;
      .mb(16);
      .fs(22);
      .lh(28);
      &.ar-popup-desc-stat {
        .mt(32);
        .mb(0);
        .fs(40);
        .lh(44);
      }
    }
    h6 {
      margin: 0;
      .fs(14);
      .lh(18);
    }
    p, ul, ol {
      .mt(0);
      .mb(16);
    }
    ul, ol {
      .pl(32);
      li {
        .mb(8);
      }
    }
    &-intro {
      margin: 0;
      .mb(16);
      .fs(18);
      .lh(22);
    }
    &-content {
      .bblr(16);
      height: calc(100% - 44px);
      overflow-y: scroll;
    }
    &-imgmask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        .top-center();
        height: auto;
        z-index: 3;
        width: 100%;
        &.ar-on-xs {
          display: block;
        }
        &.ar-on-xl {
          display: none;
        }
      }
    }
  }
}
#ar2023 .ar-popup {
  &-desc {
    .ar-popup-desc-nav {
      background-color: @colors[c2];
    }
    .ar-popup-desc-col {
      // &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      //   background-color: @colors[c2];
      // }
      &:nth-child(1) {
        .ar-popup-desc-intro {
          .fontLight;
        }
        .bblr(16);
      }
      h4, h5, h6, p, ul, ol {
        color: @colors[c3];
      }
    }
    .ar-popup-desc-col {
      &:nth-child(2) a {
        .fontRegular;
        .fs(16);
        .lh(20);
        .mt(32);
        .mb(16);
        color: @colors[c3];
        display: inline-block;
        text-decoration: none;
        position: relative;
        .pl(32);
        .pr(32);
        &:before {
          content: '';
          .h(24);
          .w(24);
          position: absolute;
          background: url('../images/icons/document-primary.svg') center center no-repeat;
          background-size: 100%;
          top: 0;
          left: 0;
        }
        &:after {
          content: '';
          .h(12);
          .w(12);
          position: relative;
          background: url('../images/icons/icon-download-primary.svg') center center no-repeat;
          background-size: 100%;
          display: inline-block;
          vertical-align: top;
          .ml(10);
          .top(4);
          right: 0;
          transition: top @easing100 linear;
        }
      }
    }
    &.ar-popup-desc-blue {
      background-color: @colors[c2];
      .ar-popup-desc-nav {
        background-color: @colors[c3];
      }
      .ar-popup-desc-col {
        &:nth-child(1) {
          background-color: @colors[c3];
          h4, h5, p, ul, ol {
            color: @colors[white];
          }
        }
      }
    }
    &.ar-popup-desc-green {
      background-color: @colors[white];
      .ar-popup-desc-col {
        &:nth-child(1) {
          background-color: @colors[c2];
          p.ar-popup-desc-intro {
            .fontRegular;
          }
        }
        // &:nth-child(2), &:nth-child(3) {
        //   background-color: @colors[white];
        // }
      }
    }
    &.ar-popup-desc-default {
      background-color: @colors[c2];
      .ar-popup-desc-col {
        h4 {
          .fs(28);
          .lh(32);
        }
        p.ar-popup-desc-intro {
          .fs(24);
          .lh(28);
        }
      }
    }
  }
}