#ar2023 .ar-banner {
  &-bgmask {
    display: block;
  }
  &-textwrap {
    .pl(0);
    .pr(0);
    > div {
      background-color: transparent;
      .mxw(1366);
      .pl(100);
      .pr(100);
      margin: 0 auto;
      width: 100%;
    }
  }
  &-text {
    width: 40%;
    h1 {
      color: @colors[white];
    }
    p {
      color: @colors[white];
    }
  }
  &-waves {
    &:before, &:after {
      width: 300vw;
      height: 300vw;
      top: -75vw;
    }
  }
}