@font-face {
  font-family: 'Calibre web';
  src: url('../fonts/calibre-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Calibre web';
  src: url('../fonts/calibre-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Calibre web';
  src: url('../fonts/calibre-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Calibre web';
  src: url('../fonts/calibre-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Calibre web';
  src: url('../fonts/calibre-thin.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}