#ar2023 .ar-downloads {
  color: @colors[c6];
  &-grid {
    .mxw(1366);
    .pl(36);
    .pr(36);
    .pt(128);
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
  }
  &-col {
    width: 100%;
  }
  h2 {
    margin: 0;
    .mb(32);
    .fs(42);
    .lh(46)
  }
  &-filegrid {
    .ml(-20);
    .mr(-20);
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &-filecol {
    width: 100%;
    .pl(20);
    .pr(0);
    .mb(26);
  }
  a {
    .fontRegular;
    .fs(16);
    .lh(20);
    .mb(16);
    color: @colors[c3];
    display: inline-block;
    text-decoration: none;
    position: relative;
    .pl(32);
    .pr(32);
    &:before {
      content: '';
      .h(24);
      .w(24);
      position: absolute;
      background: url('../images/icons/document-primary.svg') center center no-repeat;
      background-size: 100%;
      top: 0;
      left: 0;
    }
    &:after {
      content: '';
      .h(12);
      .w(12);
      position: relative;
      background: url('../images/icons/icon-download-primary.svg') center center no-repeat;
      background-size: 100%;
      display: inline-block;
      vertical-align: top;
      .ml(10);
      .top(4);
      right: 0;
      transition: top @easing100 linear;
    }
  }
  small {
    display: block;
    .fontSemibold;
    .pl(32);
    .pr(32);
    color: @colors[c6];
  }
}