#ar2023 .ar-glance {
  &-grid {
    .pl(64);
    .pr(64);
    .pb(48);
  }
  &-col {
    width: 50%;
    &:first-child {
      display: block;
      .pr(30);
    }
  }
  h2 {
    max-width: 75%;
  }
  &-statgrid {
    max-width: 600px;
  }
}